function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _object_spread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _define_property(target, key, source[key]);
        });
    }
    return target;
}
function ownKeys(object, enumerableOnly) {
    var keys = Object.keys(object);
    if (Object.getOwnPropertySymbols) {
        var symbols = Object.getOwnPropertySymbols(object);
        if (enumerableOnly) {
            symbols = symbols.filter(function(sym) {
                return Object.getOwnPropertyDescriptor(object, sym).enumerable;
            });
        }
        keys.push.apply(keys, symbols);
    }
    return keys;
}
function _object_spread_props(target, source) {
    source = source != null ? source : {};
    if (Object.getOwnPropertyDescriptors) {
        Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
        ownKeys(Object(source)).forEach(function(key) {
            Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
        });
    }
    return target;
}
import TabsComponent from "components/core/tabs/tabs-component";
import { WORK_OS_IRIS_COLOR_NAME } from "styles/color-consts";
import FullParagraphComponent from "segments/desktop/paragraph/layouts/full-paragraph/full-paragraph-component";
import TagItemsGridComponent from "segments/desktop/grid/layouts/tag-items-grid/tag-items-grid-component";
import FlexibleCardGridConfig from "segments/desktop/grid/layouts/flexible-cards-grid/flexible-cards-grid-config";
import GridImageWithParagraph from "segments/desktop/core-components/grid-image-with-paragraph/grid-image-with-paragraph";
import TwoThirdsTextAndAssetComponentDefaultProps from "segments/desktop/text-and-asset/layouts/two-thirds-text-and-asset/default-props";
const getGridImageWithParagraphProps = (imageSrc, fullParagraphTitle, fullParagraphBody)=>{
    const fullParagraph = _object_spread_props(_object_spread({}, GridImageWithParagraph.defaultProps.fullParagraph), {
        title: fullParagraphTitle,
        body: fullParagraphBody,
        textAlign: "center",
        mobileTextAlign: "center"
    });
    return _object_spread_props(_object_spread({}, GridImageWithParagraph.defaultProps), {
        src: imageSrc,
        mobileImage: {
            src: imageSrc
        },
        fullParagraph,
        cardNumOfColumnsWidth: 4
    });
};
export default _object_spread_props(_object_spread({}, TabsComponent.defaultProps), {
    tabsColor: WORK_OS_IRIS_COLOR_NAME,
    swapTagsWithTextAndAssets: false,
    tabs: [
        {
            tabLabel: "Business owner",
            textAndAssetTwoThirds: _object_spread_props(_object_spread({}, TwoThirdsTextAndAssetComponentDefaultProps), {
                fullParagraph: _object_spread({}, TwoThirdsTextAndAssetComponentDefaultProps.fullParagraph),
                asset: _object_spread_props(_object_spread({}, TwoThirdsTextAndAssetComponentDefaultProps.asset), {
                    position: "center"
                })
            }),
            fullParagraph: _object_spread_props(_object_spread({}, FullParagraphComponent.defaultProps), {
                topic: {},
                title: "Why use monday CRM",
                body: "",
                textAlign: "center",
                mobileTextAlign: "center"
            }),
            tagItems: _object_spread_props(_object_spread({}, TagItemsGridComponent.defaultProps), {
                gridTagsBackgroundColor: "transparent"
            }),
            flexibleCards: _object_spread_props(_object_spread({}, FlexibleCardGridConfig.defaultProps), {
                imagePaddingBottom: "24px",
                gridItems: [
                    getGridImageWithParagraphProps("https://dapulse-res.cloudinary.com/image/upload/remote_mondaycom_static/uploads/SafaaEek/467px-Monday_logo.svg.png", "“I like the ease of use and the ability to do everything from one main dashboard, as well as the ease of adding automations.", "Maurice W. | CEO, facilities services ")
                ]
            })
        },
        {
            tabLabel: "Sales Manager",
            textAndAssetTwoThirds: _object_spread_props(_object_spread({}, TwoThirdsTextAndAssetComponentDefaultProps), {
                fullParagraph: _object_spread({}, TwoThirdsTextAndAssetComponentDefaultProps.fullParagraph),
                asset: _object_spread_props(_object_spread({}, TwoThirdsTextAndAssetComponentDefaultProps.asset), {
                    position: "center"
                })
            }),
            fullParagraph: _object_spread_props(_object_spread({}, FullParagraphComponent.defaultProps), {
                topic: {},
                title: "Why use monday CRM",
                body: "",
                textAlign: "center",
                mobileTextAlign: "center"
            }),
            tagItems: _object_spread_props(_object_spread({}, TagItemsGridComponent.defaultProps), {
                gridTagsBackgroundColor: "transparent"
            }),
            flexibleCards: _object_spread_props(_object_spread({}, FlexibleCardGridConfig.defaultProps), {
                imagePaddingBottom: "24px",
                gridItems: [
                    getGridImageWithParagraphProps("https://dapulse-res.cloudinary.com/image/upload/remote_mondaycom_static/uploads/SafaaEek/467px-Monday_logo.svg.png", "“I like the ease of use and the ability to do everything from one main dashboard, as well as the ease of adding automations.", "Maurice W. | CEO, facilities services ")
                ]
            })
        }
    ]
});

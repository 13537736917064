import styled from "@emotion/styled";
export const TAG_IMAGE_SIZE = "44px";
export const StyledTagAnchorWrapper = styled.a`
  text-decoration: none;
  color: unset;
  cursor: pointer;
`;
export const StyledTag = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  &.has-on-click-behavior {
    cursor: pointer;
  }

  .tag-image-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;

    .tag-picture {
      .tag-icon {
        height: 100%;
      }
    }
  }

  .tag-text {
    margin-left: 8px;
    font-size: 1.125rem;
    line-height: 22px;
    font-weight: 300;
  }
`;
